import { Plugin, ButtonView } from 'ckeditor5';

export default class UnformatPlugin extends Plugin {
  init() {
    this.editor.ui.componentFactory.add('unformat', locale => {
      const view = new ButtonView(locale);

      view.set({
        label: '書式を解除',
        icon: this._createUnformatIcon(),
        tooltip: true
      });

      view.on('execute', () => {
        this._unformatSelection();
      });

      return view;
    });
  }

  _createUnformatIcon() {
    return `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g transform="skewX(-15)">
          <line x1="12" y1="4" x2="12" y2="14" stroke="black" stroke-width="2"/>
          <line x1="8" y1="4" x2="16" y2="4" stroke="black" stroke-width="2"/>
          <line x1="9" y1="16" x2="15" y2="16" stroke="black" stroke-width="2"/>
        </g>
        <text x="16" y="20" font-family="Arial" font-size="14" fill="black" text-anchor="middle">x</text>
      </svg>
    `;
  }

  _unformatSelection() {
    const model = this.editor.model;
    model.change(writer => {
      const range = model.document.selection.getFirstRange();
      if (range.isCollapsed) {
        return;
      }

      for (const item of range.getItems()) {
        writer.clearAttributes(item);
      }
    });

    this.editor.editing.view.focus();
  }
}

import { Plugin } from 'ckeditor5';

class ApplyEditorClass extends Plugin {
  // media側で定義されたcssを適用するために必要
  CLASS_FOR_MEDIA = 'm-articlePanel-section';

  init() {
    const editor = this.editor;

    const applyEditorClass = () => {
      const contentEditor = editor.ui.view.editable.element;
      if (contentEditor && !contentEditor.classList.contains(this.CLASS_FOR_MEDIA)) {
        contentEditor.classList.add(this.CLASS_FOR_MEDIA);
      }
    };

    // focus等のイベントの度に.ck-contentエリアが再描画されるため、renderイベントが発生する度にクラスを付与する必要あり
    editor.ui.view.editable.on('render', applyEditorClass);
  }
}

export default ApplyEditorClass;

import {
  ClassicEditor,
  Essentials,
  SourceEditing,
  Heading,
  Image,
  ImageUpload,
  SimpleUploadAdapter,
  ImageToolbar,
  ImageTextAlternative,
  Table,
  TableToolbar,
  Link,
  Bold,
  List,
  BlockQuote,
  GeneralHtmlSupport
} from 'ckeditor5';

import langJa from 'ckeditor5/translations/ja.js';

import 'ckeditor5/ckeditor5.css';

import BgMarker from './ckeditor/plugins/bg_marker.js';
import ColorStrong from './ckeditor/plugins/color_strong.js';
import Unformat from './ckeditor/plugins/unformat.js';
import ApplyEditorClass from './ckeditor/plugins/apply_editor_class.js';

$(function () {
  $('.js-ckeditor').each((_i, elem) => {
    ClassicEditor.create(elem,
      {
        plugins: [
          Essentials,
          SourceEditing,
          Heading,
          Image,
          ImageUpload,
          SimpleUploadAdapter,
          ImageToolbar,
          ImageTextAlternative,
          Table,
          TableToolbar,
          Link,
          Bold,
          BgMarker,
          ColorStrong,
          Unformat,
          ApplyEditorClass,
          List,
          BlockQuote,
          GeneralHtmlSupport
        ],
        toolbar: [
          'sourceEditing', '|',
          'heading', '|',
          'imageUpload',
          'insertTable',
          'link', '|',
          'bold', 'colorStrong', 'marker', 'unformat', '|',
          'numberedList',
          'bulletedList', '|',
          'blockQuote'
        ],
        simpleUpload: {
          uploadUrl: '/editor/ckeditor_assets',
          withCredentials: true,
          method: 'POST',
          headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          },
        },
        heading: {
          options: [
            { model: 'paragraph', title: '標準', class: 'ck-heading_paragraph' },
            { model: 'heading2', view: 'h2', title: '見出し2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: '見出し3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: '見出し4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: '見出し5', class: 'ck-heading_heading5' },
          ]
        },
        image: {
          toolbar: ['imageTextAlternative'],
          insert: { type: 'inline' } // NOTE: inlineの指定がないとfigureタグで囲まれ既存のスタイルが適用されない
        },
        link: {
          decorators: {
            openInNewTab: {
              mode: 'manual',
              label: 'Open in a new tab',
              attributes: {
                target: '_blank',
                rel: 'noopener noreferrer'
              }
            }
          }
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
        },
        // ソース編集モード時に許可するHTML要素の属性。これがないとソース編集モードから戻った際に属性が削除される。
        htmlSupport: {
          allow: [
            {
              attributes: true,
              classes: true,
              styles: true
            }
          ]
        },
        translations: [langJa],
      })
      .then(editor => {
        editor.model.document.on('change:data', () => {
          $('.unsaved_unload_confirm').data('changed', true);
        });
      })
      .catch(error => {
        console.error('CKEditor initialization error:', error);
      });
  });
});

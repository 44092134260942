import { Plugin, ButtonView } from 'ckeditor5';

const MODEL_NAME = 'colorStrong';

export default class ColorStrong extends Plugin {
  init() {
    this._defineTooltip();
    this._defineConverters();
  }

  _defineTooltip() {
    this.editor.ui.componentFactory.add(MODEL_NAME, locale => {
      const view = new ButtonView(locale);

      view.set({
        label: '色付き強調',
        icon: this._createColorStrongIcon(),
        tooltip: true
      });

      view.on('execute', () => {
        this._toggleColorStrong();
      });

      return view;
    });
  }

  _createColorStrongIcon() {
    return `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <text x="12" y="16" font-size="14" text-anchor="middle" fill="black">A</text>
        <line x1="4" y1="20" x2="20" y2="20" stroke="black" stroke-width="2"/>
      </svg>
    `;
  }

  _defineConverters() {
    const conversion = this.editor.conversion;

    conversion.for('downcast').attributeToElement({
      model: MODEL_NAME,
      view: (_attribute, { writer }) => {
        return writer.createAttributeElement('strong', { class: 'text-strong' }, { priority: 5 });
      }
    });

    conversion.for('upcast').elementToAttribute({
      view: {
        name: 'strong',
        classes: 'text-strong'
      },
      model: MODEL_NAME
    });
  }

  _toggleColorStrong() {
    const model = this.editor.model;
    const selection = model.document.selection;

    model.change(writer => {
      const range = selection.getFirstRange();

      if (range.isCollapsed) {
        return;
      }

      const hasColorStrong = Array.from(range.getItems()).some(item => item.hasAttribute(MODEL_NAME));
      if (hasColorStrong) {
        this._removeColorStrong(range, writer);
      } else {
        this._addColorStrong(range, writer);
      }
    });
    this.editor.editing.view.focus();
  }

  _removeColorStrong(range, writer) {
    for (const item of range.getItems()) {
      writer.removeAttribute(MODEL_NAME, item);
    }
  }

  _addColorStrong(range, writer) {
    for (const item of range.getItems()) {
      writer.setAttribute(MODEL_NAME, true, item);
    }
  }
}

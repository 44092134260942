import { Plugin, ButtonView } from 'ckeditor5';

const MODEL_NAME = 'marker';

export default class BbMarkerPlugin extends Plugin {
  init() {
    this._defineTooltip();
    this._defineConverters();
  }

  _defineTooltip() {
    this.editor.ui.componentFactory.add(MODEL_NAME, locale => {
      const view = new ButtonView(locale);

      view.set({
        label: 'マーカー',
        icon: this._createMarkerIcon(),
        tooltip: true
      });

      view.on('execute', () => {
        this._toggleMarker();
      });

      return view;
    });
  }

  _createMarkerIcon() {
    return `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="2" width="20" height="20" fill="#4F4F4F" stroke="#4F4F4F" stroke-width="2"/>
        <text x="12" y="16" font-size="14" font-weight="bold" text-anchor="middle" fill="#FFFFFF" font-family="Arial, sans-serif">A</text>
      </svg>
    `;
  }

  _defineConverters() {
    this.editor.conversion.for('downcast').attributeToElement({
      model: MODEL_NAME,
      view: (_attribute, { writer }) => {
        return writer.createAttributeElement('span', { class: 'text-mark' }, { priority: 5 });
      }
    });

    this.editor.conversion.for('upcast').elementToAttribute({
      view: {
        name: 'span',
        classes: 'text-mark'
      },
      model: MODEL_NAME
    });
  }

  _toggleMarker() {
    const model = this.editor.model;

    model.change(writer => {
      const range = model.document.selection.getFirstRange();

      if (range.isCollapsed) {
        return;
      }

      const hasMarker = Array.from(range.getItems()).some(item => item.hasAttribute(MODEL_NAME));
      if (hasMarker) {
        this._removeUnderline(range, writer);
      } else {
        this._addUnderline(range, writer);
      }
    });

    this.editor.editing.view.focus();
  }

  _removeUnderline(range, writer) {
    for (const item of range.getItems()) {
      writer.removeAttribute(MODEL_NAME, item);
    }
  }

  _addUnderline(range, writer) {
    for (const item of range.getItems()) {
      writer.setAttribute(MODEL_NAME, true, item);
    }
  }
}
